<template>
	<v-container id="crud" fluid tag="section">
		<v-card>
			<v-card-title>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Búsqueda de Secretos"
					single-line
					hide-details
				></v-text-field>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="desserts"
				:search="search"
				sort-by="secret"
				class="elevation-1"
			>
				<template v-slot:top>
					<v-toolbar flat color="white">
						<v-toolbar-title>Administración de Secretos</v-toolbar-title>
						<v-divider class="mx-4" inset vertical></v-divider>
						<v-spacer></v-spacer>

						<v-dialog v-model="dialog" max-width="30%" persistent>
							<template v-if="visible" v-slot:activator="{ on, attrs }">
								<v-btn
									:color="$cv('principal')"
									dark
									class="mx-2"
									fab
									v-bind="attrs"
									v-on="on"
									><v-icon blue>mdi-plus</v-icon></v-btn
								>
							</template>
							<v-card>
								<v-form ref="form" v-model="valid" lazy-validation>
									<v-toolbar dark :color="$cv('principal')">
										<v-btn icon dark @click="dialog = false">
											<v-icon>mdi-close</v-icon>
										</v-btn>
										<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
										<v-spacer></v-spacer>
										<v-toolbar-items>
											<v-btn dark text @click="save" :disabled="!valid"
												>Guardar</v-btn
											>
										</v-toolbar-items>
									</v-toolbar>

									<v-card-text>
										<v-container>
											<v-row>
												<v-col class="d-flex" cols="12" md="6" sm="6">
													<v-autocomplete
														:items="users"
														item-value="id"
														item-text="name"
														label="Usuario"
														v-model="editedItem.user_id"
														:rules="rulesRequired"
													></v-autocomplete>
												</v-col>
											</v-row>
											<v-row>
												<v-col cols="12" md="6" sm="6">
													<v-text-field
														v-model="editedItem.secret"
														label="Secreto"
														:rules="rulesRequired"
													></v-text-field>
												</v-col>
											</v-row>
										</v-container>
									</v-card-text>
								</v-form>
							</v-card>
						</v-dialog>
					</v-toolbar>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon
						v-if="visible"
						small
						class="mr-2"
						:color="$cv('btnEditar')"
						title="Editar"
						@click="editItem(item.id)"
					>
						mdi-pencil
					</v-icon>
					<v-icon
						v-if="visible"
						small
						:color="$cv('btnEliminar')"
						title="Eliminar"
						@click="deleteItem(item.id)"
					>
						mdi-delete
					</v-icon>
					<v-icon
						small
						color="yellow"
						title="Imprimir"
						@click="exportPDF(item.id)"
					>
						mdi-adobe-acrobat
					</v-icon>
				</template>
			</v-data-table>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			{{ text }}

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
	</v-container>
</template>

<script>
	export default {
		data: (vm) => ({
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			baseUrl: "/api/secrets",
			menu: false,
			modal: false,
			menu2: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
			search: "",
			rulesRequired: [(v) => !!v || "Requerido"],
			users: false,
			headers: [
				{
					text: "User",
					align: "start",
					sortable: false,
					value: "user",
				},
				{ text: "Secret", filterable: true, value: "secret" },
				{ text: "Created", value: "created_at" },
				{ text: "Updated", value: "updated_at" },
				{ text: "Accciones", value: "actions", sortable: false },
			],

			desserts: [],
			editedIndex: -1,
			editedItem: {
				secret: "",
				user_id: "",
			},
			editedItem: {
				secret: "",
				user_id: "",
			},
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1 ? "Registrar Secreto" : "Editar Secreto";
			},
			computedDeclaracion() {
				return this.formatDate(this.editedItem.declaracion);
			},
			filteredDataTiposArma() {
				let options = this.tiposArma;

				if (this.editedItem.tamanio_arma_id) {
					return options.filter(
						(o) => o.tamanio_arma_id == this.editedItem.tamanio_arma_id
					);
				}
			},
		},

		watch: {
			dialog(val) {
				val || this.close();
			},
		},

		created() {
			this.initialize();
			//this.getUsers();
		},

		methods: {
			addFooters(doc) {
				const pageCount = doc.internal.getNumberOfPages();

				doc.setFont("helvetica", "italic");
				doc.setFontSize(8);
				for (var i = 1; i <= pageCount; i++) {
					doc.setPage(i);
					doc.text("Fleet | Fleet", 10, 287, {
						align: "left",
					});
					doc.text("Página " + String(i) + " de " + String(pageCount), 200, 287, {
						align: "right",
					});
				}
			},

			formatDate(date) {
				if (!date) return null;

				const [year, month, day] = date.split("-");
				return `${day}/${month}/${year}`;
			},

			capitalize(s) {
				if (typeof s !== "string") return "";
				return s.charAt(0).toUpperCase() + s.slice(1);
			},

			initialize() {
				axios
					.get(this.baseUrl)
					.then((r) => {
						this.desserts = r.data;
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener armas. Error: " + error;
						this.color = "error";
					});
			},

			editItem(id) {
				axios
					.get(this.baseUrl + "/" + id)
					.then((r) => {
						this.editedItem = Object.assign({}, r.data);
						this.editedItem.user_id = Number(r.data.user_id);
						this.dialog = true;
						this.editedIndex = r.data.id;

						/*this.editedItem.tamanio_arma_id = Number(r.data.tamanio_arma_id);
				                this.editedItem.tipo_arma_id = Number(r.data.tipo_arma_id);
				                this.editedItem.estado_logistica_id = Number(r.data.estado_logistica_id);
				                this.editedItem.observaciones = r.data.observaciones ? r.data.observaciones:'';*/
					})
					.catch(function (error) {
						this.snackbar = true;
						this.text = "Error al obtener datos del secreto. Error: " + error;
						this.color = "error";
					});
			},

			deleteItem(id) {
				this.$confirm({
					message: `Eliminar Registro?`,
					button: {
						no: "Cancelar",
						yes: "Confirmar",
					},

					callback: (confirm) => {
						if (confirm) {
							axios.delete(this.baseUrl + "/" + id).then(
								(response) => {
									this.snackbar = true;
									this.text = "Registro Eliminado";
									this.initialize();
								},
								(error) => {
									this.snackbar = true;
									this.text = "Error al eliminar la planilla Error: " + e.message;
									this.color = "error";
								}
							);
						}
					},
				});
			},

			close() {
				this.dialog = false;
				this.valid = true;
				this.resetValidation();
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.editedIndex = -1;
				});
			},
			resetValidation() {
				this.$refs.form.resetValidation();
			},

			save() {
				if (this.$refs.form.validate()) {
					if (this.editedIndex > -1) {
						let formData = new FormData();
						let url = this.baseUrl + "/" + this.editedIndex;
						formData.append("_method", "PUT");

						axios
							.post(url, {
								_method: "PUT",
								data: this.editedItem,
							})
							.then((r) => {
								this.snackbar = true;
								this.text = "Registro Actualizado";

								this.initialize();
							})
							.catch((e) => {
								this.snackbar = true;
								this.text = "Error al actualizar el arma. Error: " + e.message;
								this.color = "error";
							});
					} else {
						let method = "post";
						let url = this.baseUrl;

						axios({
							method,
							url,
							data: this.editedItem,
						})
							.then((r) => {
								this.snackbar = true;
								this.initialize();
							})
							.catch((e) => {
								console.log(e);
								this.snackbar = true;
								this.text = "Error al ingresar un arma. Error: " + e.message;
								this.color = "error";
							});
					}

					this.close();
				}
			},
			getUsers() {
				axios
					.get("/api/users")
					.then((response) => {
						this.users = response.data;
					})
					.catch((response) => {
						this.snackbar = true;
						this.text = "Error al cargar usuarios. Error: " + e.message;
						this.color = "error";
					});
			},
		},
		mounted() {
			console.log("Componente Secretos creado");
		},
	};
</script>
